<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Shop By Look
            <v-spacer></v-spacer>
            <v-flex xs12 sm3 md3 pt-5 align-self-end text-right>
              <v-select
                outlined
                v-model="addedby"
                :items="affilatorArray"
                item-value="_id"
                item-text="firstname"
                dense
                label="Select Affiliator"
                required
              ></v-select>
            </v-flex>
            <v-flex xs12 sm2 md2>
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="600px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    <span>Add Theme</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addcat" ref="addcat">
                    <v-card-title>
                      <span class="headline">Add Theme</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="name"
                              label="Theme Name"
                              required
                              :rules="Rules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="title"
                              label="Theme Title"
                              required
                              :rules="Rules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="caption"
                              label="Theme Caption"
                              :rules="Rules"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="12" md="12">
                            <v-select
                              v-model="shopByLookCategory"
                              :items="shopByLookCategoryArray"
                              item-text="name"
                              item-value="_id"
                              dense
                              label="Shop By Look Category"
                              required
                              :rules="Rules"
                              @change="getTags(shopByLookCategory)"
                            ></v-select>
                          </v-col>
                          <!-- <v-flex xs12 pl-2>
                            <v-combobox
                              v-model="tags"
                              :items="tagArray"
                              chips
                              clearable
                              label="Tags"
                              multiple
                              class="pt-3"
                            >
                              <template
                                v-slot:selection="{
                                  attrs,
                                  item,
                                  select,
                                  selected,
                                }"
                              >
                                <v-chip
                                  v-bind="attrs"
                                  :input-value="selected"
                                  close
                                  @click="select"
                                  @click:close="remove(tags, item)"
                                >
                                  <strong> {{ item }} </strong>&nbsp;
                                </v-chip>
                              </template>
                            </v-combobox>
                          </v-flex> -->
                          <v-flex py-2 text-left pl-2 xs12 md12 lg12>
                            <span
                              style="
                                padding-left: 10px;
                                text-align: left;
                                letter-spacing: 0.54px;
                                font-size: 12px;
                              "
                              >Add Products</span
                            >
                            <v-layout wrap justify-space-between>
                              <v-flex
                                v-for="(item, i) in shopByLookProducts"
                                :key="i"
                                pa-2
                                xs12
                              >
                                <v-card tile outlined elevation="1">
                                  <v-layout wrap justify-start>
                                    <v-flex xs12 align-self-center text-right>
                                      <v-icon
                                        color="red"
                                        @click="removeTheme(i)"
                                      >
                                        mdi-close
                                      </v-icon>
                                    </v-flex>
                                    <v-flex xs6 sm3 pb-5 text-left>
                                      <v-img
                                        :src="baseURL + item.image"
                                        aspect-ratio="1"
                                        contain
                                        height="100px"
                                      ></v-img>
                                    </v-flex>
                                    <v-flex xs6 sm9>
                                      {{ item.productname }}
                                      <!-- </v-flex>
                                    <v-flex xs12> -->
                                      <v-layout wrap justify-start>
                                        <v-flex
                                          xs12
                                          sm6
                                          md4
                                          pa-2
                                          v-for="(list, j) in item.tags"
                                          :key="j"
                                        >
                                          <v-chip
                                            style="cursor: pointer"
                                            outlined
                                          >
                                            {{ list }}
                                          </v-chip>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-card>
                              </v-flex>
                              <v-flex pa-2>
                                <v-dialog
                                  v-model="shopDialog"
                                  max-width="700px"
                                  transition="dialog-transition"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-chip
                                      v-on="on"
                                      style="cursor: pointer"
                                      color="red"
                                      outlined
                                    >
                                      <span style="color: #000000">
                                        <v-icon>mdi-plus</v-icon> Add Products
                                      </span>
                                    </v-chip>
                                  </template>
                                  <v-card class="pa-2">
                                    <v-layout wrap>
                                      <v-flex xs12 text-left>
                                        <span
                                          style="
                                            padding-left: 10px;
                                            text-align: left;
                                            letter-spacing: 0.54px;
                                            font-size: 13px;
                                          "
                                          >Add Products</span
                                        >
                                      </v-flex>
                                      <!-- <v-flex xs12 py-2>
                                        <v-text-field
                                          v-model="keyword"
                                          outlined
                                          label="Search"
                                          single-line
                                          hide-details
                                        ></v-text-field>
                                      </v-flex> -->
                                      <v-flex xs12>
                                        <v-autocomplete
                                          v-model="product"
                                          :items="productArray"
                                          outlined
                                          item-text="productname"
                                          item-value="_id"
                                          dense
                                          placeholder="Add Products"
                                          color="#FF1313"
                                          :rules="Rules"
                                        >
                                          <template
                                            slot="item"
                                            slot-scope="productArray"
                                          >
                                            <v-layout wrap justify-center>
                                              <v-flex xs10 py-2>
                                                {{
                                                  productArray.item.productname
                                                }}
                                              </v-flex>

                                              <v-flex xs2 py-2>
                                                <v-img
                                                  :src="
                                                    baseURL +
                                                    productArray.item.photos[0]
                                                  "
                                                  aspect-ratio="1"
                                                  contain
                                                  height="50px"
                                                ></v-img>
                                              </v-flex>
                                            </v-layout>
                                          </template>
                                        </v-autocomplete>
                                      </v-flex>
                                      <v-flex xs12 v-if="product">
                                        <v-img
                                          :src="
                                            baseURL + curProduct[0].photos[0]
                                          "
                                          aspect-ratio="1"
                                          contain
                                          height="100px"
                                        ></v-img>
                                      </v-flex>
                                      <v-flex xs12>
                                        <v-combobox
                                          v-model="tags"
                                          :items="tagArray"
                                          chips
                                          dense
                                          clearable
                                          outlined
                                          label="Tags"
                                          multiple
                                          class="pt-3"
                                        >
                                          <template
                                            v-slot:selection="{
                                              attrs,
                                              item,
                                              select,
                                              selected,
                                            }"
                                          >
                                            <v-chip
                                              v-bind="attrs"
                                              :input-value="selected"
                                              close
                                              @click="select"
                                              @click:close="remove(tags, item)"
                                            >
                                              <strong> {{ item }} </strong
                                              >&nbsp;
                                            </v-chip>
                                          </template>
                                        </v-combobox>
                                      </v-flex>
                                    </v-layout>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="shopDialog = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="addProduct()"
                                      >
                                        Add
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-flex>
                              <!-- </v-layout> -->
                            </v-layout>
                            <!-- </v-chip-group> -->
                          </v-flex>
                          <v-col cols="12">
                            <v-switch
                              v-model="showInHomePage"
                              label="Show In Home Page"
                            ></v-switch>
                          </v-col>
                          <v-col cols="12">
                            <ImageComp
                              :singleImage="mainImagePreview"
                              @stepper="winStepper"
                              :height="'350'"
                              :width="'350'"
                              :heading="'Upload Main Image (350 * 350)'"
                              :componentType="'mainImage'"
                            />
                            <!-- (1195 X 804) -->
                          </v-col>
                           <v-col cols="12">
                            <ImageComp
                              :singleImage="mobileMainImagePreview"
                              @stepper="winStepper"
                              :height="'250'"
                              :width="'250'"
                              :heading="'Upload Mobile Main Image (250 * 250)'"
                              :componentType="'mobileMainImage'"
                            />
                            <!-- (1195 X 804) -->
                          </v-col>
                          <!-- <v-col cols="12">
                            <ImageComp
                              :singleImage="productImagePreview"
                              @stepper="winStepper"
                              :heading="'Upload Prodcut Image'"
                              :componentType="'productImage'"
                            />
                          </v-col> -->
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog = false">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addcat"
                        @click="themeAdd()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-card-title>
          <v-flex v-for="(item, i) in user" :key="i">
            <v-layout wrap justify-start py-4>
              <v-flex xs12 sm12 md6 lg6 xl6 text-left pb-2 pb-md-0>
                <!-- <v-btn block large tile outlined> -->
                <span
                  style="
                    font-size: 25px;
                    color: #000000;
                    font-weight: bold;
                    letter-spacing: 3px;
                  "
                >
                  {{ item.shopByLookCategory.name }}
                </span>
                <!-- </v-btn> -->
              </v-flex>
            </v-layout>
            <!-- Products -->
            <v-layout wrap justify-start v-if="item.data">
              <v-flex v-if="item.data.length > 0" xs12 sm12 md12 py-2 py-md-0>
                <v-layout wrap>
                  <v-flex xs12 pa-2 v-for="(list, i) in item.data" :key="i">
                    <ShopByLookItem
                      :key="i"
                      v-bind:storage="list"
                      v-bind:category="item.shopByLookCategory.tags"
                      v-bind:productArray="productArray"
                      v-bind:cardWidth="'200px'"
                      @stepper="winStepper1"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- <v-layout wrap justify-center v-else>
            <v-flex xs12>
              <span style="font-size: 25px; color: #000000">
                Oops! No Products Found
              </span>
            </v-flex> -->
          <!-- </v-layout> -->

          <!-- <v-layout wrap justify-center v-if="user">
            <v-flex xs12>
              <v-pagination
                v-model="currentPage"
                :length="pages"
                :total-visible="7"
                color="#FF0000"
              ></v-pagination>
            </v-flex>
          </v-layout> -->
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
import ShopByLookItem from "./shopByLookItem";
export default {
  components: {
    ImageComp,
    ShopByLookItem,
  },
  data() {
    return {
      owlResponsive: {
        0: { items: 2, nav: false },
        600: { items: 2, nav: false },
        960: { items: 4, nav: false },
        1264: { items: 5, nav: false },
        1920: { items: 6, nav: false },
      },
      editing: "",
      editId: "",
      appLoading: false,
      search: "",
      msg: "",
      file: null,
      file1:"",
      coverFile: null,
      mainImagePreview: "",
      mobileMainImagePreview:"",
      productImagePreview: "",
      image: "",
      mainImage: "",
      mobileMainImage:"",
      productImage: "",
      currentPage: 1,
      showsnackbar: false,
      ServerError: false,
      shopDialog: false,
      dialog: false,
      addcat: false,
      shopByLookProducts: [],
      product: "",
      productArray: [],
      name: "",
      title: "",
      caption: "",
      tags: "",
      tagArray: [],
      shopByLookCategory: "",
      shopByLookCategoryArray: [],
      showInHomePage: "",
      offer: "",
      pages: 0,
      editingitem: "",
      editdialog: false,
      count: 15,
      totalData: 0,
      totalRows: 0,
      user: [],
      affilatorArray: [],
      addedby: "",
      selectedTags: [],
      Rules: [(v) => !!v || "Required"],
      curProduct: null,
      curProductEdit: null,
      imgId: "",
      shopDialogEdit: false,
      edit: null,
      editproduct: null,
      keyword: "",
    };
  },
  mounted() {
    this.getData();
    this.getCategory();
    this.getProducts();
    this.getAffilator();
  },
  watch: {
    keyword() {
      this.getSearch();
    },
    addedby() {
      this.getData();
    },
    currentPage() {
      this.getData();
    },
    product() {
      this.curProduct = null;
      var filtData = this.productArray.filter((x) => x._id == this.product);
      console.log("Filt=", filtData);
      this.curProduct = filtData;
    },
    editproduct() {
      this.curProductEdit = null;
      var filtData = this.productArray.filter((x) => x._id == this.editproduct);
      console.log("Filt=", filtData);
      this.curProductEdit = filtData;
    },
  },
  methods: {
    getTags(tags) {
      var current = this.shopByLookCategoryArray.filter((x) => {
        if (x._id == tags) {
          this.tagArray = x.tags;
          return x.tags;
        }
      });
      // if (current.length > 0) {
      //   if (current[0]) {
      //     var display = current[0].tags;
      //     this.shopByLookCategoryArray.push(display);
      //   }
      // }
      console.log("ghh", current);
    },
    remove(tags, i) {
      console.log("index", tags);
      const index = tags.indexOf(i);
      if (index > -1) {
        tags.splice(index, 1); // 2nd parameter means remove one item only
      }

      console.log("spliced", tags);
    },
    winStepper(window_data) {
      if (window_data.type == "mainImage") {
        this.file = window_data.selectedFiles;
      }
      if (window_data.type == "mobileMainImage") {
        this.file1 = window_data.selectedFiles;
      }
    },
    winStepper1(item) {
      if (item.ref == "edit") {
        if (item.getData) {
          this.getData();
        }
      }
    },
    removeTheme(i) {
      this.shopByLookProducts.splice(i, 1);
      console.log("Removed=", this.shopByLookProducts);
    },
    removeThemeEdit(k, j) {
      this.editing.data[j].data.splice(k, 1);
      console.log("Removed=", this.shopByLookProducts);
    },
    addProduct() {
      var pushThis = {};
      pushThis.productname = this.curProduct[0].productname;
      pushThis.product = this.curProduct[0]._id;
      pushThis.image = this.curProduct[0].photos[0];
      pushThis.tags = JSON.parse(JSON.stringify(this.tags));
      this.shopByLookProducts.push(pushThis);
      this.shopDialog = false;
      console.log("pushed=", this.shopByLookProducts);
    },
    addProductEdit(j) {
      var pushThis = {};
      pushThis.productname = this.curProductEdit[0].productname;
      pushThis.product = this.curProductEdit[0]._id;
      pushThis.image = this.curProductEdit[0].photos[0];
      // pushThis.tags = this.tags;
      this.editing.data[j].data.push(pushThis);
      //   this.shopDialog = false;
      console.log("pushed=", this.editing.data[j].data);
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/shopbylook/theme/getlistgrouped/admin",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          addedby: this.addedby,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/shopbylook/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.shopByLookCategoryArray = response.data.data;
          for (let i = 0; i < this.shopByLookCategoryArray.length > 0; i++) {
            this.tagArray = this.shopByLookCategoryArray[i].tags;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    getAffilator() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/affliator/dropdown",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.affilatorArray = response.data.data;
          this.affilatorArray.unshift({ firstname: "All", _id: null });
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    editCategoryDialog(item) {
      console.log("content=", item);
      this.edit = true;
      this.editing = item;
      this.editdialog = true;
      this.shopByLookCategory = item.shopByLookCategory;
      this.product = item.product;
      this.showInHomePage = item.showInHomePage;
      this.editId = item._id;
    },
    getSearch() {
      this.appLoading = true;
      axios({
        url: "/product/dropdown",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: 10,
          page: 1,
          keyword: this.keyword,
          image: true,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.productArray = response.data.data;
            this.appLoading = false;
          } else {
            this.showsnackbar = false;
            this.product = [];
            this.msg = response.data.msg;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProducts() {
      this.appLoading = true;
      axios({
        url: "/product/dropdown",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: 0,
          page: 0,
          image: true,
          // keyword: this.keyword,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.productArray = response.data.data;
            this.appLoading = false;
          } else {
            this.showsnackbar = false;
            this.product = [];
            this.msg = response.data.msg;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    themeAdd() {
      this.addDialog = false;
      var data = {};
      data["name"] = this.name;
      data["title"] = this.title;
      data["caption"] = this.caption;
      data["shopByLookCategory"] = this.shopByLookCategory;
      data["shopByLookProducts"] = this.shopByLookProducts;
      data["showInHomePage"] = this.showInHomePage;
      // data["tags"] = this.tags;
      axios({
        url: "/shopbylook/theme/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.imgId = response.data.id;
            if (this.file) {
              this.uploadImage(this.imgId);
            } 
            if (this.file1) {
              this.uploadMobileImage(this.imgId);
            }
            else {
              this.getData();
            }
            this.msg = "Theme Added!";
            this.showsnackbar = true;
            this.dialog = false;
            this.name = null;
            this.title = null;
            this.caption = null;
            this.shopByLookCategory = null;
            this.product = null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editTheme() {
      this.addDialog = false;
      var data = {};
      data["name"] = this.list.theme.name;
      data["title"] = this.list.theme.title;
      data["caption"] = this.list.theme.caption;
      data["shopByLookCategory"] = this.editing.shopByLookCategory;
      // data["shopByLookProducts"] = this.editing.data[j];
      data["showInHomePage"] = this.list.theme.showInHomePage;
      data["tags"] = this.tags;
      axios({
        url: "/shopbylook/theme/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.imgId = response.data.id;
            if (this.file) {
              this.uploadImage(this.imgId);
            } else {
              this.getData();
            }
            this.msg = "Theme Added!";
            this.showsnackbar = true;
            this.dialog = false;
            this.name = null;
            this.title = null;
            this.caption = null;
            this.shopByLookCategory = null;
            this.product = null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadMobileImage(id){
      let formData = new FormData();
      formData.append("photo", this.file1);
      formData.append("id", id);
      axios({
        method: "POST",
        url: "/shopbylook/theme/appimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.getData();

            // this.$router.go(-1);
          } else {
            this.appLoading = false;

            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("photo", this.file);
      formData.append("id", id);
      axios({
        method: "POST",
        url: "/shopbylook/theme/mainimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.getData();

            // this.$router.go(-1);
          } else {
            this.appLoading = false;

            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    rowClick(item) {
      this.$router.push("/productDetails/" + item._id);
    },
  },
};
</script>
